import { sanitize } from 'dompurify'

interface SafeHtmlObject {
	__html: string
}

function createMarkup(dirty: string): SafeHtmlObject {
	return { __html: sanitize(dirty) }
}
export default createMarkup
