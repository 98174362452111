{
	"name": "view360-client",
	"version": "2.4.2",
	"description": "",
	"scripts": {
		"lint": "eslint src/web/components/**/* --quiet",
		"test": "jest",
		"clean": "rm -rf .parcel-cache && rm -rf ./dist && mkdir dist",
		"copy": "cp -r ./public/* ./dist",
		"build": "npm run clean && npm run copy && parcel build index.html",
		"build:desktop": "cross-env NODE_ENV=electron npm run build && npm run copyForDesktop",
		"build:dev": "cross-env NODE_ENV=development npm run build",
		"build:staging": "cross-env NODE_ENV=staging npm run build",
		"build:prod": "cross-env NODE_ENV=production npm run build",
		"copyForDesktop": "rm -rf ../view360-desktop/webapp/* && cp -r ./dist/* ../view360-desktop/webapp/",
		"deploy:ci": "npm run clean && npm run build && aws s3 sync dist/ s3://$S3_BUCKET --delete && npm run invalidate:ci",
		"deploy:dev": "npm run clean && npm run build:dev && aws s3 sync dist/ s3://view360-web-dev --delete --profile view360 && npm run invalidate:dev",
		"deploy:staging": "npm run clean && npm run build:staging && aws s3 sync dist/ s3://view360-web-staging --delete --profile view360 && npm run invalidate:staging",
		"deploy:prod": "npm run clean && npm run build:prod && aws s3 sync dist/ s3://view360-web-live --delete --profile view360 && npm run invalidate:prod",
		"deploy:v1": "npm run clean && npm run build:prod && aws s3 sync dist/ s3://view360-v1 --delete --profile view360 && npm run invalidate:prod",
		"start": "npm run start:dev",
		"start:dev": "cross-env NODE_ENV=development parcel index.html -p 8010",
		"start:dev:desktop": "cross-env NODE_ENV=electronlocal parcel index.html -p 8010",
		"start:staging": "cross-env NODE_ENV=staging parcel index.html -p 8010",
		"start:prod": "cross-env NODE_ENV=productionlocal parcel index.html -p 8010",
		"updatebrowserlist": "npx browserslist@latest --update-db",
		"invalidate:ci": "aws cloudfront create-invalidation --distribution-id $DISTRIBUTION_ID --paths \"/index.html\"",
		"invalidate:dev": "aws cloudfront create-invalidation --profile view360 --distribution-id E36Q0C7FIL71GU --paths \"/index.html\"",
		"invalidate:staging": "aws cloudfront create-invalidation --profile view360 --distribution-id E2ANOJ57T6T6HB --paths \"/index.html\"",
		"invalidate:prod": "aws cloudfront create-invalidation --profile view360 --distribution-id E3OYPKS2Z2XFWT --paths \"/index.html\""
	},
	"browserslist": [
		"since 2017-06"
	],
	"keywords": [],
	"author": "Cognitas Global Ltd.",
	"license": "UNLICENSED",
	"dependencies": {
		"@reduxjs/toolkit": "^2.1.0",
		"@sentry/browser": "^7.8.1",
		"@sentry/esbuild-plugin": "^2.14.0",
		"@sentry/react": "^7.100.1",
		"@sentry/tracing": "^7.8.1",
		"@sentry/types": "^7.8.1",
		"agora-rtc-sdk-ng": "^4.20.0",
		"agora-rtm-sdk": "^2.1.7",
		"browserslist": "^4.16.3",
		"color-interpolate": "^1.0.5",
		"copy-html-to-clipboard": "^4.0.1",
		"dompurify": "^3.2.3",
		"fine-uploader": "^5.16.2",
		"fine-uploader-wrappers": "^1.0.1",
		"history": "^4.10.1",
		"immer": "^2.1.5",
		"intl-tel-input": "^17.0.16",
		"isomorphic-ws": "^4.0.1",
		"jquery": "^3.6.0",
		"jwt-decode": "^2.2.0",
		"lib-jitsi-meet-dist": "^2.1.5",
		"libphonenumber-js": "^1.9.49",
		"lodash.uniq": "^4.5.0",
		"logrocket": "^1.0.10",
		"md5": "^2.2.1",
		"prop-types": "^15.7.2",
		"query-string": "^7.1.1",
		"react": "^16.14.0",
		"react-beautiful-dnd": "^13.1.1",
		"react-dom": "^16.14.0",
		"react-fine-uploader": "^1.1.1",
		"react-hot-loader": "^4.12.20",
		"react-intl-tel-input-v2": "^1.0.8",
		"react-pdf": "^5.7.2",
		"react-phone-number-input": "^3.1.38",
		"react-quill": "^2.0.0",
		"react-quill-new": "^3.3.3",
		"react-redux": "^7.2.1",
		"react-router-dom": "^5.2.0",
		"react-select": "^3.1.0",
		"react-switch": "^5.0.1",
		"react-textarea-autosize": "^8.4.0",
		"react-transition-group": "^4.4.2",
		"react-use": "^15.3.3",
		"rect-scaler": "^1.0.0",
		"redux": "^4.0.5",
		"redux-thunk": "^2.3.0",
		"regenerator-runtime": "^0.13.5",
		"save-dev": "0.0.1-security",
		"socket.io-client": "^2.3.0",
		"sweetalert2": "^9.10.2",
		"symbol-observable": "^4.0.0",
		"try-json": "^1.0.1",
		"twilio-video": "^2.26.0",
		"typescript-interface-generator": "0.0.6",
		"ua-parser-js": "^1.0.33",
		"uuid": "^8.3.0"
	},
	"devDependencies": {
		"@babel/core": "^7.16.12",
		"@parcel/packager-raw-url": "^2.8.3",
		"@parcel/transformer-sass": "^2.8.3",
		"@parcel/transformer-webmanifest": "^2.8.3",
		"@types/md5": "^2.3.0",
		"@types/react": "^16.9.49",
		"@types/react-redux": "^7.1.16",
		"@types/react-router-dom": "^5.1.8",
		"@types/react-select": "^4.0.15",
		"@typescript-eslint/eslint-plugin": "^4.1.0",
		"@typescript-eslint/parser": "^4.1.0",
		"aws-sdk": "^2.864.0",
		"babel-jest": "^26.3.0",
		"buffer": "^5.7.1",
		"cross-env": "^5.2.1",
		"csv-parse": "^5.3.10",
		"dotenv-flow": "^3.2.0",
		"esbuild": "^0.17.15",
		"esbuild-runner": "^2.2.2",
		"eslint": "^6.7.2",
		"eslint-config-airbnb": "^18.1.0",
		"eslint-config-airbnb-typescript": "^10.0.0",
		"eslint-config-prettier": "^6.10.0",
		"eslint-plugin-import": "^2.19.1",
		"eslint-plugin-jsx-a11y": "^6.2.3",
		"eslint-plugin-prettier": "^3.1.2",
		"eslint-plugin-react": "^7.19.0",
		"events": "^3.3.0",
		"jest": "^26.4.2",
		"parcel": "^2.11.0",
		"prettier": "^2.2.1",
		"redux-devtools-extension": "^2.13.9",
		"runscript": "^1.4.0",
		"sass": "^1.35.2",
		"typescript": "^4.0.2"
	}
}
