/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */

import { connect } from 'react-redux'
import React, { FunctionComponent } from 'react'

import Actions from '../actions'
import config from '../../config'

import BubbleHeading from '../elements/BubbleHeading'
import IconButton from '../elements/IconButton'
import createMarkup from '../util/createMarkup'

import getTextDirection from '../util/getTextDirection'
import IncomingItemToast from './IncomingItemToast'

type UpdateModalProps = {
	update?: { text?: string; update?: string }
	displayModalPopup?: (modalName: string) => void
}

const UpdateModal: FunctionComponent<UpdateModalProps> = props => {
	const { update } = props
	const close = () => props.displayModalPopup('')
	const text = update.text || update.update
	return (
		<div className="update-modal">
			<IncomingItemToast />
			<div className="update-modal__main" onClick={close} onKeyPress={close} role="dialog">
				<IconButton iconName="close" onClick={close} />
				<BubbleHeading>{config.strings.NEWSTYPE_UPDATE}</BubbleHeading>
				<div className="update-modal__content" style={{ direction: getTextDirection(text) }}>
					<div dangerouslySetInnerHTML={createMarkup(text)} />
				</div>
			</div>
		</div>
	)
}

// =================================================================================================
// Redux wiring
// =================================================================================================
const mapStateToProps = () => ({})
const actions = {
	displayModalPopup: Actions.misc.displayModalPopup,
}
export default connect(mapStateToProps, actions)(UpdateModal)
