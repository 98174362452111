import React, { FunctionComponent, useEffect, useState } from 'react'
import { connect } from 'react-redux'

import config from '../../../config'
import Actions from '../../actions'

import createMarkup from '../../util/createMarkup'
import getMediaFeed, { NotificationData } from '../../selectors/mediaFeed'
import releasesSelector from '../../selectors/releases'
import sessionSelector from '../../selectors/session'
import IconButton from '../../elements/IconButton'
import AppHeaderBar from './AppHeaderBar'
import calculateTranslationHash from '../../util/calculateTranslationHash'
import DivWithInert from '../../elements/DivWithInert'

type UpdateScreenProps = {
	notification: NotificationData
	scheduleRow: ScheduleRow
	closeMedia: () => void
	translations?: Record<string, string>
	facilitatorLanguage: string
	language: string
	translate: (text: string, toLang: string, fromLang?: string) => void
	active?: boolean
}

function formatNotificationTime(time: number | string): string {
	return new Date(time).toTimeString().substr(0, 5)
}

const UpdateScreen: FunctionComponent<UpdateScreenProps> = props => {
	const { closeMedia, scheduleRow, notification } = props
	const { translations, translate, language, facilitatorLanguage } = props
	const { active } = props
	const { update } = scheduleRow

	const { UPDATE, SHOW_TRANSLATION, SHOW_ORIGINAL, RECEIVED_AT } = config.strings

	const onClose = () => {
		closeMedia()
	}

	// Check for translation
	const [showOriginal, setShowOriginal] = useState(false)
	const translationHash = calculateTranslationHash(update, facilitatorLanguage, language)
	useEffect(() => {
		if (!translations[translationHash]) translate(update, language, facilitatorLanguage)
	}, [translationHash])
	const translation = translations[translationHash]
	const text = showOriginal ? update : translation || update

	// Auto-close the update after 20 seconds
	useEffect(() => {
		if (active && text) {
			const timer = setTimeout(() => {
				closeMedia()
			}, 15000)

			return () => {
				clearTimeout(timer)
			}
		}
	}, [active, text, closeMedia])

	const onClickTranslate = () => setShowOriginal(!showOriginal)

	return (
		<DivWithInert className="update-screen" inert={!active}>
			<AppHeaderBar active={active} onClickAlert={onClose} />
			<div className="app-header">
				<IconButton iconName="chevron-left-alt" onClick={onClose} />
				<h2>{UPDATE}</h2>
			</div>
			<p>
				{RECEIVED_AT} {formatNotificationTime(notification.time)}
			</p>

			<div className="update-screen__content">
				<div dangerouslySetInnerHTML={createMarkup(text)} />
			</div>

			{translation && (
				<div
					className="update-screen__translate"
					onClick={onClickTranslate}
					role="button"
					tabIndex={0}
					onKeyPress={onClickTranslate}
				>
					{showOriginal ? SHOW_TRANSLATION : SHOW_ORIGINAL}
				</div>
			)}
		</DivWithInert>
	)
}

// =================================================================================================
// Redux wiring
// =================================================================================================
const mapStateToProps = (state: StateTree) => {
	const { selectedScheduleRowId } = state
	const session = sessionSelector(state)
	const { schedule } = session
	const scheduleRow = schedule?.find(r => r.id === selectedScheduleRowId) || ({} as ScheduleRow)

	const releases = releasesSelector(state)
	const groupId = state?.group?.id
	const notifications = groupId ? getMediaFeed(groupId, session, releases) : []
	const notification = notifications.find(n => n.rowId === selectedScheduleRowId) || ({} as NotificationData)

	return {
		scheduleRow,
		notification,

		language: state?.group?.language || 'EN',
		translations: state?.translations || {},
		facilitatorLanguage: 'EN',
	}
}
const actions = {
	markMediaAsSeen: Actions.media.markMediaAsSeen,
	closeMedia: Actions.media.closeMedia,
	translate: Actions.translate.translate,
}

// Create a type "OwnProps" which only includes props that are not from Redux state/actions
type PropsFromState = ReturnType<typeof mapStateToProps>
type ReduxActions = typeof actions
type OwnProps = Omit<UpdateScreenProps, keyof (PropsFromState & ReduxActions)>

export default connect<PropsFromState, ReduxActions, OwnProps>(mapStateToProps, actions)(UpdateScreen)
