import React, { FunctionComponent } from 'react'

import Icon from './Icon'

type IconButtonProps = {
	selected?: boolean
	onClick?: () => void
	title?: string
	iconName: string
	showBadge?: boolean
	enabled?: boolean
	className?: string
	text?: string
	showTooltip?: boolean
}

const IconButton: FunctionComponent<IconButtonProps> = props => {
	const { selected, onClick, title, iconName, showBadge, className, text, enabled = true, showTooltip = true } = props
	let _className = 'icon-button'
	if (selected) {
		_className += ' icon-button--selected'
	}
	if (!enabled) {
		_className += ' icon-button--disabled'
	}
	if (className) {
		_className += ` ${className}`
	}

	function _onClick(e: React.BaseSyntheticEvent) {
		if (onClick && enabled) {
			e.stopPropagation()
			onClick(e)
		}
	}
	return (
		<div
			className={_className}
			onClick={_onClick}
			title={showTooltip ? title || text : null}
			role="button"
			aria-label={title}
			tabIndex={0}
			onKeyPress={_onClick}
		>
			<Icon name={iconName} />
			{showBadge && <div className="badge" />}
			{text && <div className="icon-button__text">{text}</div>}
		</div>
	)
}

IconButton.defaultProps = {
	className: '',
	showBadge: false,
	selected: false,
	onClick: null,
	enabled: true,
	title: null,
	text: '',
	showTooltip: false,
}

export default IconButton
