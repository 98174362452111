/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */

import { connect } from 'react-redux'
import React, { FunctionComponent, useEffect, useState } from 'react'

import Actions from '../actions'
import config from '../../config'
import createMarkup from '../util/createMarkup'

import getTextDirection from '../util/getTextDirection'
import IncomingItemToast from './IncomingItemToast'
import Button from '../elements/Button'
import sessionSelector from '../selectors/session'
import Row from '../elements/Row'
import calculateTranslationHash from '../util/calculateTranslationHash'

export type InitialInformationModalProps = {
	update?: { text?: string; update?: string }
	// displayModalPopup?: (modalName: string) => void
	closeSessionInformation: () => void
	session: CurrentSession

	translations?: Record<string, string>
	facilitatorLanguage: string
	language: string
	translate: (text: string, toLang: string, fromLang?: string) => void
}

const InitialInformationModal: FunctionComponent<InitialInformationModalProps> = props => {
	const { session, closeSessionInformation } = props
	const { translations, translate, language, facilitatorLanguage } = props

	const { INITIAL_UPDATE_INDEX, CONTINUE } = config.strings
	const close = () => closeSessionInformation()
	const update = session.initialUpdate

	const { SHOW_TRANSLATION, SHOW_ORIGINAL } = config.strings

	// Check for translation
	const [showOriginal, setShowOriginal] = useState(false)
	const translationHash = calculateTranslationHash(update, facilitatorLanguage, language)
	useEffect(() => {
		if (!translations[translationHash]) translate(update, language, facilitatorLanguage)
	}, [translationHash])
	const translation = translations[translationHash]
	const text = showOriginal ? update : translation || update

	const onClickTranslate = () => setShowOriginal(!showOriginal)

	return (
		<div className="initial-information-modal">
			<IncomingItemToast />
			<div className="initial-information-modal__main" role="dialog">
				<div className="initial-information-modal__content" style={{ direction: getTextDirection(text) }}>
					<h2>{INITIAL_UPDATE_INDEX.replace('%%%', String(session.index))}</h2>
					<div dangerouslySetInnerHTML={createMarkup(text)} />
					<Row className="initial-information-modal__button-row">
						<Button primary onClick={close}>
							{CONTINUE}
						</Button>
						{translation && (
							<div className="initial-information-modal__translate" onClick={onClickTranslate} role="button">
								{showOriginal ? SHOW_TRANSLATION : SHOW_ORIGINAL}
							</div>
						)}
					</Row>
				</div>
			</div>
		</div>
	)
}

// =================================================================================================
// Redux wiring
// =================================================================================================
const mapStateToProps = (state: StateTree) => ({
	session: sessionSelector(state),
	language: state?.group?.language || 'EN',
	translations: state?.translations || {},
	facilitatorLanguage: 'EN',
})
const actions = {
	// displayModalPopup: Actions.misc.displayModalPopup,,
	closeSessionInformation: Actions.sessions.closeSessionInformation,
	translate: Actions.translate.translate,
}

// Create a type "OwnProps" which only includes props that are not from Redux state/actions
type PropsFromState = ReturnType<typeof mapStateToProps>
type ReduxActions = typeof actions
type OwnProps = Omit<InitialInformationModalProps, keyof (PropsFromState & ReduxActions)>

export default connect<PropsFromState, ReduxActions, OwnProps>(mapStateToProps, actions)(InitialInformationModal)
